import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import type { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { match } from 'ts-pattern';

import {
  type DtoEnrollable,
  ModelsAssignmentType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../services/api-service';
import { buildReactSelectStyles } from '../../../../utils/react-select';

export type EnrollableOption = {
  kind: 'existing';
  enrollable: DtoEnrollable;
  value: string;
};

interface EnrollableMultiSelectProps {
  options: EnrollableOption[];
  onChange: (val: EnrollableOption[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

async function loadAllEnrollables(): Promise<EnrollableOption[]> {
  try {
    const resp = await apiService.learning.searchEnrollables({});
    const enrollables = resp.data.results;
    return enrollables.map((e) => ({
      kind: 'existing' as const,
      enrollable: e,
      value: e.objectId,
    }));
  } catch {
    return [];
  }
}

async function searchEnrollablesByName(
  query: string
): Promise<EnrollableOption[]> {
  try {
    const resp = await apiService.learning.searchEnrollables({ q: query });
    const enrollables = resp.data.results;
    return enrollables.map((e) => ({
      kind: 'existing' as const,
      enrollable: e,
      value: e.objectId,
    }));
  } catch {
    return [];
  }
}

async function loadEnrollables(
  inputValue: string
): Promise<EnrollableOption[]> {
  if (!inputValue) {
    return await loadAllEnrollables();
  } else {
    return await searchEnrollablesByName(inputValue);
  }
}

const debouncedLoad = debounce(
  (inputValue: string, callback: (val: EnrollableOption[]) => void) => {
    loadEnrollables(inputValue)
      .then((results) => callback(results))
      .catch(() => callback([]));
  },
  200
);

function getAssignmentTypeLabel(type: ModelsAssignmentType): string {
  return match(type)
    .with(ModelsAssignmentType.AssignmentTypeCourse, () => 'Course')
    .with(ModelsAssignmentType.AssignmentTypeStack, () => 'Stack')
    .otherwise(() => 'Unknown');
}

export function EnrollableMultiSelect(props: EnrollableMultiSelectProps) {
  const { options, onChange, placeholder, disabled } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<EnrollableOption, true>({
        override: {
          control: { minHeight: 48 },
          multiValue: () => ({ background: '#8C6FFF' }), // Purple color for course/stack badges
        },
      }),
    []
  );

  function loadOptions(
    inputValue: string,
    callback: (options: EnrollableOption[]) => void
  ): void {
    debouncedLoad(inputValue, callback);
  }

  function getOptionValue(option: EnrollableOption) {
    return option.enrollable.objectId;
  }

  function formatOptionLabel(
    option: EnrollableOption,
    { context }: { context: 'menu' | 'value' }
  ) {
    return match(option)
      .with({ kind: 'existing' }, (o) => {
        const typeLabel = getAssignmentTypeLabel(o.enrollable.type);

        if (context === 'menu') {
          return (
            <div className='flex flex-col'>
              <span className='text-white text-sms font-normal'>
                {o.enrollable.name}
              </span>
              <span className='text-3xs text-white/80'>{typeLabel}</span>
            </div>
          );
        }

        return (
          <span className='text-white text-xs font-normal'>
            {o.enrollable.name}
          </span>
        );
      })
      .exhaustive();
  }

  function handleChange(newValue: MultiValue<EnrollableOption>) {
    onChange(newValue.slice()); // convert from readonly array
  }

  return (
    <AsyncSelect<EnrollableOption, true>
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder || 'Search for courses and stacks...'}
      value={options}
      onChange={handleChange}
      styles={styles}
      isDisabled={disabled}
      classNamePrefix='select-box-v2'
      noOptionsMessage={() => 'No matching courses or stacks'}
    />
  );
}

export function EnrollableSelector({
  onSelectionChange,
  placeholder,
  disabled,
  className = '',
  label = 'Select Courses/Stacks',
}: {
  onSelectionChange?: (selectedIds: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
}) {
  const [selectedOptions, setSelectedOptions] = useState<EnrollableOption[]>(
    []
  );

  const handleChange = (options: EnrollableOption[]) => {
    setSelectedOptions(options);

    if (onSelectionChange) {
      const selectedIds = options.map((option) => option.value);
      onSelectionChange(selectedIds);
    }
  };

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label && (
        <label className='text-white text-sm font-medium'>{label}</label>
      )}
      <EnrollableMultiSelect
        options={selectedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
}
