import React from 'react';

interface SortIconProps {
  direction?: 'asc' | 'desc' | null;
  className?: string;
}

export const SortIcon: React.FC<SortIconProps> = ({
  direction,
  className = 'w-3.5 h-3.5',
}) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
    >
      <path
        d='M4.17 10.352v-6.08l.668.692a.683.683 0 00.948.032.638.638 0 00.033-.919L3.992 2.2A.693.693 0 003.5 2a.71.71 0 00-.49.198l-1.83 1.88a.638.638 0 00.034.919.683.683 0 00.948-.033l.669-.691v6.079c0 .172.07.336.196.458a.68.68 0 00.946 0 .638.638 0 00.196-.458z'
        className={direction === 'asc' ? 'fill-white' : 'fill-icon-gray'}
      ></path>
      <path
        d='M12.82 9.922a.647.647 0 00.18-.466.632.632 0 00-.213-.453.674.674 0 00-.482-.174.688.688 0 00-.467.207l-.669.691V3.648a.638.638 0 00-.196-.458.68.68 0 00-.946 0 .638.638 0 00-.196.458v6.08l-.67-.692a.683.683 0 00-.948-.032.638.638 0 00-.033.919l1.83 1.879a.692.692 0 00.49.198.71.71 0 00.49-.198l1.83-1.88z'
        className={direction === 'desc' ? 'fill-white' : 'fill-icon-gray'}
      ></path>
    </svg>
  );
};
