import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import {
  LearningAnalyticsPage,
  type LearningAnalyticsPageProps,
} from '../components/Training/Admin/Analytics/LearningAnalyticsPage';
import { getLogger } from '../logger/logger';
import { apiService } from '../services/api-service';
import { type LearningAnalyticsFilterParams } from '../services/api-service/learning.api';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

const logger = getLogger().scoped('learning-analytics-client');

export async function clientLoader(
  action: ClientLoaderFunctionArgs
): Promise<LearningAnalyticsPageProps> {
  const url = new URL(action.request.url);
  const tab = url.searchParams.get('tab') ?? 'learners';
  const startDate = url.searchParams.get('startDate') ?? null;
  const endDate = url.searchParams.get('endDate') ?? null;
  const groupIds = url.searchParams.get('groupIds') ?? null;
  const courseIds = url.searchParams.get('courseIds') ?? null;
  const sort = url.searchParams.get('sort') ?? null;
  const dir = url.searchParams.get('dir') ?? null;

  const params: LearningAnalyticsFilterParams = {
    tab,
  };

  if (startDate) {
    params.startDate = startDate;
  }

  if (endDate) {
    params.endDate = endDate;
  }

  if (groupIds) {
    params.groupIds = groupIds;
  }

  if (courseIds) {
    params.courseIds = courseIds;
  }

  if (sort) {
    params.sortField = sort;
  }

  if (dir) {
    params.sortDirection = dir;
  }

  try {
    const analytics = await tokenWithRedirect(
      () => apiService.learning.getLearningAnalytics(params),
      action.request.url
    );

    return {
      data: analytics.data,
      initialTab: tab,
    };
  } catch (error) {
    logger.error('Failed to load analytics data', error);
    throw error;
  }
}

export function Component() {
  const props = useLoaderData<typeof clientLoader>();
  return <LearningAnalyticsPage {...props} />;
}
